import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Container } from 'react-bootstrap'
import cn from 'classnames'
import * as s from './style.module.scss'

export const WorldBlock = ({ title, description, list, image, rtl }) => {
  const data = useStaticQuery(graphql`
    {
      allImageSharp {
        edges {
          node {
            fixed {
              originalName
            }
            gatsbyImageData(layout: CONSTRAINED, width: 279, height: 550, placeholder: TRACED_SVG)
          }
        }
      }
    }
  `)

  const imageNode = data.allImageSharp.edges.find(({ node: { fixed: { originalName } } }) =>
    originalName.includes(image)
  )

  return (
    <Container as="section" className={s.world}>
      <div className={cn(s.world__img, { [s.rtl]: rtl })}>
        <GatsbyImage image={imageNode.node.gatsbyImageData} alt={image} />
      </div>
      <div className={s.world__content}>
        <h2 className={s.world__title}>{title}</h2>
        <p className={s.world__descr}>{description}</p>
        {list && (
          <ol className={s.world__list}>
            {list.map((item) => (
              <li className={s.world__item} key={item}>
                {item}
              </li>
            ))}
          </ol>
        )}
      </div>
    </Container>
  )
}
