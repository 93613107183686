import React from 'react'
import { Container } from 'react-bootstrap'
import { Form } from '~components/Form'
import { cta } from './style.module.scss'

export const Cta = () => {
  return (
    <Container as="section" className={cta}>
      <Form />
    </Container>
  )
}
