export default {
  HERO: {
    title: 'SolClout World',
    description:
      'SolClout enhances DeFi through implementation of social activities. SolClout Social is a blockchain-based social networking service embedded within the application to improve the defi experience for the average user. Users can enjoy aggregated news, trades, and information about cryptocurrencies and finance.',
  },
  WORLD: [
    {
      title: 'SolClout Trading Competitions',
      description:
        'Compete in trading competitions to earn rewards in cryptocurrencies. Experience an engaging, competing experience that will reward you for your hard work and financial knowledge.',
      image: 'ranktrades',
      list: ['SolClout Trading Competition', '1st Trading Competition', 'Annual Trading Competition'],
    },
    {
      title: 'Social Trading Maximized',
      description:
        'You can be the next-level trader by following updates from other traders and influencers through notification.',
      image: 'ranklist',
      rtl: true,
    },
    {
      title: 'Blockchain-based Feed',
      description:
        'Finally enjoy an anti-censorship feed that will not censor your work or content like current social platforms.',
      image: 'worldfeed',
    },
  ],
}
