import * as React from 'react'
import Layout from '~components/Layout'
import SEO from '~components/seo'
import Hero from '~components/Hero'

import { WorldBlock } from './components/WorldBlock'
import { Cta } from './components/Cta'

import DATA from './data'
import { wrapper } from './style.module.scss'

export const World = () => (
  <Layout>
    <SEO title={DATA.HERO.title} />
    <Hero {...DATA.HERO} modifier="world" />
    <div className={wrapper}>
      {DATA.WORLD.map((item) => (
        <WorldBlock key={item.title} {...item} />
      ))}
    </div>
    <Cta />
  </Layout>
)
